.page {
  max-width: 100%;
}
.contentForm {
  padding: 8px 16px;
}

.MuiChip-root {
  margin: 2px;
}

.MuiListItem-root {
  border: 1px solid #E8E8E8 !important;
}

.MuiListSubheader-gutters {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.quoteButton {
  margin-top: 24px !important;
}

.quoteInput {
  margin: 8px !important;
  margin-bottom: 16px !important;
}

.quoteList {
  margin: 16px !important;
  display: block;
}

.quoteList.is__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 146px);
  gap: 8px;
  justify-content: center;
}

.itemContainer {
  background-color: #F4F4FA;
  border-radius: 15px;
  margin: 12px 0px;
}

.tagsContent {
  margin: 0px 16px 16px 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.tagItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 33.33%;
}

.tagItem > span {
  margin-left: 5px;
  margin-bottom: 6px;
}

.titleSection {
  margin: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #4B4B4B;
  margin: 1rem 0;
}

.titleSection b {
  color: rgba(0, 0, 0, 0.54);
}

.proposals .BrainhubCarousel__container {
  max-width: 634px;
}

.proposals {
  max-width: 982px;
}

.proposals.mobile {
  display: none;
}

.multiTagContent {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 16px;
  border-radius: 15px;
  padding: 27px 23px;
  position: relative;
}

.multiTagContent div {
  margin: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.multiTagContent div:first-child {
  margin-left: 0px;
}

.multiTagContent div:last-child {
  margin-right: 0px;
}

.multiTagContent span {
  display: block;
  max-width: 135px;
}

.multiTagContent span:first-child {
  font-weight: 600;
  line-height: 1.5;
}

.multiTagContent .closeBtn {
  color: #fff;
  position: absolute;
  right: 0px;
  top: 0px;
}

.item__add-btn {
  display: flex;
  border: 1px dashed #C4C4C4;
  padding: 30px 50px;
  border-radius: 15px;
  margin-top: 20px;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  cursor: pointer;
}

.item__add-btn svg {
  margin-right: 12px;
}

.item__add-btn b {
  font-size: 14px;
  margin-left: 12px;
}

.mobileButtonRow button span.show {
  display: none;
  align-items: center;
}

@media (max-width: 415px) {
  .mobileButtonRow button span:not(.MuiButton-label):not(.MuiTouchRipple-root):not(.show) {
    display: none;
  }

  .mobileButtonRow button span.show {
    display: flex;
  }

  .tagItem {
    width: 50%;
  }

  .MuiAccordionDetails-root .tagsContent > div {
    width: 100%;
    margin-bottom: 1rem;
  }

  .MuiAccordionDetails-root .tagsContent > div .MuiChip-root {
    max-width: 100% !important;
  }

  .MuiAccordionDetails-root .tagsContent > div .MuiChip-label {
    overflow: auto;
    white-space: normal;
  }
}

@media (max-width: 992px) {
  .proposals .BrainhubCarousel__container {
    max-width: 635px;
  }
}

@media (max-width: 778px) {

  .proposals.desktop {
    display: none;
  }

  .proposals.mobile {
    display: flex;
  }

  .proposals .BrainhubCarousel__container {
    max-width: 356px;
  }
}
